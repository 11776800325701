import './App.css';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import { BrowserRouter, Routes, Route } from "react-router-dom";
require("dotenv").config();

function App() {
  return(
    // <Home></Home>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
