import React, { useEffect, useState } from "react";
import Web3 from "web3";
import abi from "./abi.json";
import axios from "axios";
import "./Home.css";
import NavMidImage from "./assets/NavMidImage.png";
import InstagramIcon from "./assets/InstagramIcon.png";
import YoutubeIcon from "./assets/YoutubeIcon.png";
import MIcon from "./assets/MIcon.png";
import DiscordIcon from "./assets/DiscordIcon.png";
import TwitterIcon from "./assets/TwitterIcon.png";
import ShopButton from "./assets/ShopButton.png";
import LBH1 from "./assets/LBH1.png";
import LBH2 from "./assets/LBH2.png";
import LBH3 from "./assets/LBH3.png";
import T1 from "./assets/T1.png";
import T2 from "./assets/T2.png";
import LBHGif from "./assets/LBH.gif";
import Tier1 from "./assets/Tier1.png";
import Tier2 from "./assets/Tier2.png";
import Tier3 from "./assets/Tier3.png";
import Tier4 from "./assets/Tier4.png";
import Tier5 from "./assets/Tier5.png";
import Processing from "./assets/Processing.png";
import Completed from "./assets/Completed.png";
import RoadMapSnakeHead from "./assets/RoadMapSNakeHead.png";
import RoadMapSnake from "./assets/RoadMapSnale.png";
import Team1 from "./assets/Team1.png";
import Team2 from "./assets/Team2.png";
import Team3 from "./assets/Team3.png";
import Team4 from "./assets/Team4.png";
import Team5 from "./assets/Team5.png";
import SocialInsta from "./assets/SocialInsta.png";
import SocialTwitter from "./assets/SocialTwitter.png";
import LBHTrailer from "./assets/LBHTrailer.mp4";
import { useNavigate } from "react-router-dom";

require("dotenv").config();
const { REACT_APP_CONTRACT_ADDRESS, REACT_APP_BASE_URL } = process.env;

export default function Home() {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [showMintButton, setShowMintButton] = useState(false);

  const [whitelistMinted, setWhitelistMinted] = useState(0);

  const [isPublicSalePaused, setIsPublicSalePaused] = useState(true);
  const [isPreSalePaused, setIsPreSalePaused] = useState(true);

  const [number, setNumber] = useState(1);
  const [merkel, setMerkel] = useState();
  const [rootHash, setRootHash] = useState();

  const [userWhiteListCounter, setUserWhiteListCounter] = useState(0);

  const [contract, setContract] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [supply, setTokenSupply] = useState(null);

  const [whiteListPrice, setWhiteListePrice] = useState();
  const [whiteListPriceInEth, setWhiteListPriceInEth] = useState(0.055);

  const [publicPrice, setPublicPrice] = useState();
  const [publicPriceInEth, setPublicPriceInEth] = useState(0.077);

  const [minted, setMinted] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);

  useEffect(() => {
    loadWeb3();
  }, []);

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      const web3 = window.web3;
      const contractaddress = REACT_APP_CONTRACT_ADDRESS;

      setLoader(true);

      // CONTRACT
      const contract = new web3.eth.Contract(abi, contractaddress);
      setContract(contract);
      console.log(contract, "contract");

      // WHITELIST PRICE
      let whiteListPrice = await contract.methods.preSalePrice().call();
      console.log(whiteListPrice, "whitelistprice");
      setWhiteListePrice(whiteListPrice);
      setWhiteListPriceInEth(web3.utils.fromWei(whiteListPrice, "ether"));

      // PUBLIC PRICE
      let publicPrice = await contract.methods.publicSaleprice().call();
      console.log(publicPrice, "publicprice");
      setPublicPrice(publicPrice);
      setPublicPriceInEth(web3.utils.fromWei(publicPrice, "ether"));

      // TOTAL SUPPLY
      const totalSupply = await contract.methods.totalSupply().call();
      console.log(totalSupply, "totalsupply");
      setTokenSupply(totalSupply);

      // IS PUBLIC SALE PAUSED
      const IsPublicSalePaused = await contract.methods
        .isPublicSalePaused()
        .call();
      console.log(IsPublicSalePaused, "IsPublicSalePaused");
      setIsPublicSalePaused(IsPublicSalePaused);

      // IS PRE SALE PAUSED
      const IsPreSalePaused = await contract.methods.isPreSalePaused().call();
      console.log(IsPreSalePaused, "IsPreSalePaused");
      setIsPreSalePaused(IsPreSalePaused);

      setLoader(false);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }
  async function connectWallet() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      let splitedMetaMaskAddress;
      if (metaMaskAccount) {
        splitedMetaMaskAddress =
          metaMaskAccount[0].substring(0, 6) +
          "......" +
          metaMaskAccount[0].substring(
            metaMaskAccount[0].length - 4,
            metaMaskAccount[0].length
          );
      }
      setWalletConnected(true);
      const address = await web3.eth.getAccounts();
      console.log(address, "address");
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }
  function increment() {
    let total = 3;
    if (!isPreSalePaused) {
      total = 3;
    } else if (!isPublicSalePaused) {
      total = 2;
    }
    if (number < total) {
      setNumber((prevNumber) => prevNumber + 1);
    }
  }
  function decrement() {
    if (number > 1) {
      setNumber((prevNumber) => prevNumber - 1);
    }
  }
  async function whiteListMint() {
    setLoader(true);
    try {
      const web3 = window.web3;
      const _value = whiteListPrice;
      const address = await web3.eth.getAccounts();
      const merkelResponse = await getMerkelProof(address.toString());
      const rootHash = merkelResponse.data.rootHash;
      const merkleProof = merkelResponse.data.merkleProof;
      console.log(merkelResponse, "merkle response");
      await contract.methods
        .whiteListMint(merkleProof, rootHash, number)
        .send({ from: address.toString(), value: _value * number });

      const totalSupply = await contract.methods.totalSupply().call();
      setTokenSupply(totalSupply);
      setNumber(1);

      // GETTING USER WHITE LIST COUNTER
      const userInContractList = await contract.methods
        ._preSaleAddresses(address[0])
        .call();
      if (userInContractList && userInContractList.counter) {
        setWhitelistMinted(parseInt(userInContractList.counter));
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }
  async function normalMint() {
    setLoader(true);
    try {
      const web3 = window.web3;
      const _value = publicPrice;
      const address = await web3.eth.getAccounts();
      await contract.methods
        .mint(number)
        .send({ from: address.toString(), value: _value * number });

      const totalSupply = await contract.methods.totalSupply().call();
      setTokenSupply(totalSupply);
      setNumber(1);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }
  async function getMerkelProof(addr) {
    return await axios.post(`${REACT_APP_BASE_URL}/api/getWhitelistMerkel`, {
      senderAddress: addr,
    });
  }

  return (
    <div className="MainContainer">
      {/*Section One*/}
      <div className="SectionOne">
        <div className="LoopingMarquee">
          <div className="Track">
            <div className="Content">
              MINTING DATE TBA <span className="Circle">o</span> STILL HIDING
              IN THE METAVERSE <span className="Circle">o</span>MINTING DATE
              TBA <span className="Circle">o</span> STILL HIDING IN THE
              METAVERSE <span className="Circle">o</span>MINTING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>MINTING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>MINTING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>
            </div>
          </div>
        </div>
        <div className="container">
          {/* NavBar */}
          <div className="NavBarMargins flex justify-between flex-col lg:flex-row">
            <div className="FlexDisplay basis-full pt-16 md:W-350">
              <img src={ShopButton} className="ShopButton CursorPointer" />
            </div>
            <div className="FlexDisplay basis-full pt-16 md:W-350">
              <div className="MidImage">
                <img src={NavMidImage} className="CursorPointer" />
              </div>
            </div>
            <div className="FlexDisplay basis-full pt-16 md:W-350">
              <a
                href="https://www.youtube.com/channel/UCPUuQcasRV7K70C4CAggxsg"
                target="_blank"
              >
                <img
                  src={YoutubeIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a href="https://medium.com/@lonelyboxheads" target="_blank">
                <img src={MIcon} className="NavIconPadding CursorPointer" />
              </a>
              <a href="https://twitter.com/LonelyBoxHeads" target="_blank">
                <img
                  src={TwitterIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a href="https://google.com" target="_blank">
                <img
                  src={DiscordIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a
                href="https://www.instagram.com/lonelyboxheadsofficial/"
                target="_blank"
              >
                <img
                  src={InstagramIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
            </div>
          </div>

          {/* LBH Images */}
          <div className="LBHSection FlexDisplay">
            <img src={T1} className="T1" />
            <img src={T2} className="T2" />
            <img src={LBH1} className="LBHImage" />
            <img src={LBH2} className="LBHImage LBH2" />
            <img src={LBH3} className="LBHImage LBH3" />
          </div>

          {loader ? (
            <div className="loader">
              <div className="Spinner"></div>
            </div>
          ) : (
            <div>
              <div className="MintCount text-6xl md:text-8xl">
                {supply} / 2000
              </div>
              <div className="BoxHeadMinted">Box HeadMinted</div>
              <div className="MintContainer">
                {walletConnected ? (
                  !isPreSalePaused || !isPublicSalePaused ? (
                    <div>
                      <div className="flex align-items-center justify-center">
                        <div className="incrimentButton" onClick={decrement}>
                          -
                        </div>
                        <div className="mintNumber">{number}</div>
                        <div className="incrimentButton" onClick={increment}>
                          +
                        </div>
                      </div>
                      <div className="flex align-items-center justify-center">
                        {!isPublicSalePaused ? (
                          <div className="mintNow" onClick={normalMint}>
                            MINT
                          </div>
                        ) : (
                          <div className="mintNow" onClick={whiteListMint}>
                            PRESALE MINT
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="SalePaused">SALE IS PAUSED RIGHT NOW</div>
                  )
                ) : (
                  <div
                    className="sm:text-4xl ConnectWallet"
                    onClick={connectWallet}
                  >
                    Connect Wallet
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* TILTED SLIDER */}
        <div className="LoopingMarquee TiltedSlider">
          <div className="Track">
            <div className="Content">
              MINTING DATE TBA <span className="Circle">o</span> STILL HIDING
              IN THE METAVERSE <span className="Circle">o</span>MINTING DATE
              TBA <span className="Circle">o</span> STILL HIDING IN THE
              METAVERSE <span className="Circle">o</span>MINTING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>MINTING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>MINTING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION Two */}
      <div className="container">
        <div className="CheckOutTrailer">CHECK OUT TRAILER</div>
        <div className="Video">
          <video className="VideoStyle" controls>
            <source src={LBHTrailer} type="video/mp4" />
            <source src={LBHTrailer} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* TILTED SLIDER */}
      <div className="LoopingMarquee TiltedSlider">
        <div className="Track">
          <div className="Content">
            MINTING DATE TBA <span className="Circle">o</span> STILL HIDING IN
            THE METAVERSE <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>
          </div>
        </div>
      </div>

      {/* Section Three */}
      <div className="SectionThree">
        <div className="container">
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 MT--10 PB-30">
            <div className="flex align-center justify-end grid-cols-2 md:grid-cols-1">
              <img className="GifStyle" src={LBHGif} />
            </div>
            <div className="flex align-center justify-start grid-cols-2 md:grid-cols-1">
              <div className="TextContainer">
                <div className="HeadingText">WHAT IS LONELY BOX HEAD?</div>
                <div className="DescriptionText">
                  Lonely Box Heads is a collection of 2000 unique Box Head NFTs
                  — unique digital collectables. The proportion of adults in the
                  globe today are lonely than ever before, and they are looking
                  for closure in the shape of love, an object, music, or an
                  episode of a popular sitcom. Box Head is a representation of
                  these lonely people; a representation of people who due to
                  fear, anxiety, shyness and the likes of their loneliness, 'box
                  up' their emotions in their head, whilst drifting further away
                  from socialization.
                </div>
              </div>
            </div>
          </div>

          <div className="PB-66">
            <div className="grid grid-cols-1 md:grid-cols-3 sm:py-5 PriceGrid">
              <div className="grid-cols-1 GridSection">
                <div className="GridHeading">Price</div>
                <div className="GridText">0.035ETH</div>
              </div>

              <div className="grid-cols-1 GridSection">
                <div className="GridHeading">Amount</div>
                <div className="GridText">2000</div>
              </div>

              <div className="grid-cols-1 GridSection">
                <div className="GridHeading">Mint</div>
                <div className="GridText">2 per wallet</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Section Four*/}
      <div className="SectionFour">
        <div className="TieringSystem">Tiering system</div>
        <div className="flex justify-center align-items-center flex-col md:flex-row">
          <div className="TierContainer">
            <img src={Tier1} />
            <div className="TierText">
              Tier <span className="TierStr">1</span>
            </div>
          </div>
          <div className="TierContainer">
            <img src={Tier2} />
            <div className="TierText">
              Tier <span className="TierStr">2</span>
            </div>
          </div>
        </div>

        <div className="flex justify-center align-items-center flex-col md:flex-row">
          <div className="TierContainer">
            <img src={Tier3} />
            <div className="TierText">
              Tier <span className="TierStr">3</span>
            </div>
          </div>
          <div className="TierContainer">
            <img src={Tier4} />
            <div className="TierText">
              Tier <span className="TierStr">4</span>
            </div>
          </div>
          <div className="TierContainer">
            <img src={Tier5} />
            <div className="TierText">
              Tier <span className="TierStr">5</span>
            </div>
          </div>
        </div>
      </div>

      {/* TILTED SLIDER */}
      <div className="LoopingMarquee">
        <div className="Track">
          <div className="Content">
            MINTING DATE TBA <span className="Circle">o</span> STILL HIDING IN
            THE METAVERSE <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>MINTING DATE TBA{" "}
            <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
            <span className="Circle">o</span>
          </div>
        </div>
      </div>

      {/*Section Five And Six*/}
      <div className="container">
        <div className="flex justify-content-end align-items-center flex-col md:flex-row">
          <div className="CategoryBox">
            <img src={Processing} />
            <span>PROCESSING</span>
          </div>
          <div className="CategoryBox">
            <img src={Completed} />
            <span>COMPLETED</span>
          </div>
        </div>
        <div className="RoadMapHeading">Roadmap</div>
        <img src={RoadMapSnakeHead} className="RoadMapIcon" />
        <div className="align-items-center justify-center hidden md:flex">
          <div>
            <div className="StepBox MT-60">
              <div className="flex align-items-center justify-start">
                <img src={Processing} />
                <span className="Steps">Step 1</span>
              </div>
              <div className="StepType">(Processing)</div>
              <div className="StepText">
                2000 Box Heads enter the metaverse.
              </div>
            </div>

            <div className="StepBox">
              <div className="flex align-items-center justify-start">
                <img src={Processing} />
                <span className="Steps">Step 3</span>
              </div>
              <div className="StepType">(Processing)</div>
              <div className="StepText">
                Disney was built around Mickey Mouse. Mickey Mouse's significant
                impact on Walt Disney is how our LBH are to us. And what they
                will do for the LBH brand.What exactly does this imply? You'll
                learn more if you hold your LBH.
              </div>
            </div>

            <div className="StepBox">
              <div className="flex align-items-center justify-start">
                <img src={Processing} />
                <span className="Steps">Step 5</span>
              </div>
              <div className="StepType">(Processing)</div>
              <div className="StepText">
                Most Nft projects in the long term will fail, and we recognise
                this. In addition, 2022 will be the year of gaming nfts. So we
                will create a VR space in the metaverse for LBH owners. It'll be
                called "The Lonely Room". This room with a crazy architectural
                design will allow people to relax, interact and even display
                their LBH's. Did Somebody mention treasure hunts?
              </div>
            </div>

            <div className="StepBox">
              <div className="flex align-items-center justify-start">
                <img src={Processing} />
                <span className="Steps">Step 7</span>
              </div>
              <div className="StepType">(Processing)</div>
              <div className="StepText">
                Roadmap 2 will come out. Unfortunately, this is still to remain
                an enigma.
              </div>
            </div>
          </div>

          <img src={RoadMapSnake} className="RoadMapSnake hidden md:block" />

          <div>
            <div className="StepBox">
              <div className="flex align-items-center justify-start">
                <img src={Processing} />
                <span className="Steps">Step 2</span>
              </div>
              <div className="StepType">(Processing)</div>
              <div className="StepText">
                For the lucky few that mint 1/1, they are attached with
                real-life tangible paintings that will be shipped to you.
              </div>
            </div>

            <div className="StepBox">
              <div className="flex align-items-center justify-start">
                <img src={Processing} />
                <span className="Steps">Step 4</span>
              </div>
              <div className="StepType">(Processing)</div>
              <div className="StepText">
                Merch is created, Brand Building, All social media is to be
                ramped up, collabs with projects and reputable influencers (not
                paid shill), will be done. The objective is to build as much
                value as possible. Floor price shouldn't be a focus for this
                project as that is and will never be our plan. Our visions are
                long term, and we hope to underpromise and overdeliver.
              </div>
            </div>

            <div className="StepBox">
              <div className="flex align-items-center justify-start">
                <img src={Processing} />
                <span className="Steps">Step 6</span>
              </div>
              <div className="StepType">(Processing)</div>
              <div className="StepText">
                Many Nft's have excellent roadmaps with competent teams.
                However, most fail to deliver, miss dates, and soft rug, which
                results in people getting upset. Nevertheless, we have an
                achievable roadmap that will be fulfilled along with surprises
                along the way.
              </div>
            </div>
          </div>
        </div>

        <div className="align-items-center justify-center flex flex-col md:hidden">
          <div className="StepBox MT-60">
            <div className="flex align-items-center justify-start">
              <img src={Processing} />
              <span className="Steps text-lg">Step 1</span>
            </div>
            <div className="StepType">(Processing)</div>
            <div className="StepText">2000 Box Heads enter the metaverse.</div>
          </div>

          <div className="StepBox">
            <div className="flex align-items-center justify-start">
              <img src={Processing} />
              <span className="Steps">Step 2</span>
            </div>
            <div className="StepType">(Processing)</div>
            <div className="StepText">
              For the lucky few that mint 1/1, they are attached with real-life
              tangible paintings that will be shipped to you.
            </div>
          </div>

          <div className="StepBox">
            <div className="flex align-items-center justify-start">
              <img src={Processing} />
              <span className="Steps">Step 3</span>
            </div>
            <div className="StepType">(Processing)</div>
            <div className="StepText">
              Disney was built around Mickey Mouse. Mickey Mouse's significant
              impact on Walt Disney is how our LBH are to us. And what they will
              do for the LBH brand.What exactly does this imply? You'll learn
              more if you hold your LBH.
            </div>
          </div>

          <div className="StepBox">
            <div className="flex align-items-center justify-start">
              <img src={Processing} />
              <span className="Steps">Step 4</span>
            </div>
            <div className="StepType">(Processing)</div>
            <div className="StepText">
              Merch is created, Brand Building, All social media is to be ramped
              up, collabs with projects and reputable influencers (not paid
              shill), will be done. The objective is to build as much value as
              possible. Floor price shouldn't be a focus for this project as
              that is and will never be our plan. Our visions are long term, and
              we hope to underpromise and overdeliver.
            </div>
          </div>

          <div className="StepBox">
            <div className="flex align-items-center justify-start">
              <img src={Processing} />
              <span className="Steps">Step 5</span>
            </div>
            <div className="StepType">(Processing)</div>
            <div className="StepText">
              Most Nft projects in the long term will fail, and we recognise
              this. In addition, 2022 will be the year of gaming nfts. So we
              will create a VR space in the metaverse for LBH owners. It'll be
              called "The Lonely Room". This room with a crazy architectural
              design will allow people to relax, interact and even display their
              LBH's. Did Somebody mention treasure hunts?
            </div>
          </div>

          <div className="StepBox">
            <div className="flex align-items-center justify-start">
              <img src={Processing} />
              <span className="Steps">Step 6</span>
            </div>
            <div className="StepType">(Processing)</div>
            <div className="StepText">
              Many Nft's have excellent roadmaps with competent teams. However,
              most fail to deliver, miss dates, and soft rug, which results in
              people getting upset. Nevertheless, we have an achievable roadmap
              that will be fulfilled along with surprises along the way.
            </div>
          </div>

          <div className="StepBox">
            <div className="flex align-items-center justify-start">
              <img src={Processing} />
              <span className="Steps">Step 7</span>
            </div>
            <div className="StepType">(Processing)</div>
            <div className="StepText">
              Roadmap 2 will come out. Unfortunately, this is still to remain an
              enigma.
            </div>
          </div>
        </div>

        <div className="MeetTheTeam">MEET THE TEAM</div>
        <div className="OurTeamText">
          Our team is mostly doxxed. We don't shy away from representing LBH!
        </div>

        <div
          className="LearnAboutTeam CursorPointer"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalXl"
        >
          Learn more about our team
        </div>

        <div
          className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id="exampleModalXl"
          tabIndex="-1"
          aria-labelledby="exampleModalXlLabel"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-xl relative w-auto pointer-events-none">
            <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div className="ModalHeaderStyling modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  className="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalXlLabel"
                ></h5>
                <button
                  type="button"
                  className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="ModalBodyStyling modal-body relative p-4">
                <div className="ModelHeading">Meet the team</div>
                <div className="ModalDesc">
                  We currently have five team members who see to it that LBH is
                  functional
                </div>

                {/*Tabs*/}
                <ul
                  className="nav nav-tabs nav-justified flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
                  id="tabs-tabJustify"
                  role="tablist"
                >
                  <li
                    className="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-homeJustify"
                      className="nav-link w-full block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                      id="tabs-1-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-1-Justify"
                      role="tab"
                      aria-controls="tabs-homeJustify"
                      aria-selected="true"
                    >
                      Adeife
                    </a>
                  </li>
                  <li
                    className="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-profileJustify"
                      className="nav-link w-full block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                      id="tabs-2-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-2-Justify"
                      role="tab"
                      aria-controls="tabs-profileJustify"
                      aria-selected="false"
                    >
                      Alex
                    </a>
                  </li>
                  <li
                    className="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-messagesJustify"
                      className="nav-link w-full block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                      id="tabs-3-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-3-Justify"
                      role="tab"
                      aria-controls="tabs-messagesJustify"
                      aria-selected="false"
                    >
                      Daniel
                    </a>
                  </li>
                  <li
                    className="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-messagesJustify"
                      className="nav-link w-full block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                      id="tabs-4-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-4-Justify"
                      role="tab"
                      aria-controls="tabs-messagesJustify"
                      aria-selected="false"
                    >
                      Adebimpe
                    </a>
                  </li>
                  <li
                    className="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-messagesJustify"
                      className="nav-link w-full block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                      id="tabs-5-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-5-Justify"
                      role="tab"
                      aria-controls="tabs-messagesJustify"
                      aria-selected="false"
                    >
                      Musa
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="tabs-tabContentJustify">
                  <div
                    className="tab-pane fade show active"
                    id="tabs-1-Justify"
                    role="tabpanel"
                    aria-labelledby="tabs-1-tabJustify"
                  >
                    <div className="flex align-items-top justify-between">
                      <div className="TabContent">
                        <div className="TabContentHeader">Adeife Adeniran</div>
                        <div className="TabContentDesc">visual artist</div>
                        <div className="TabContentDetails">
                          I am Adeife Adeniran, a visual artist from Lagos,
                          Nigeria and creator of 'Box Head'. I'm a typical
                          introvert and I love everything creative (art, film,
                          music, etc). In my art practice, I experiment in
                          various media (photography, collage, digital) but
                          primarily I create works in painting. My work is
                          greatly informed by personal experiences and
                          observations, and I explore the theme of Loneliness
                          and associated emotions. I seek to use my art to evoke
                          conversations and raise awareness about Loneliness.
                        </div>
                      </div>
                      <img src={Team1} className="TabImage" />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tabs-2-Justify"
                    role="tabpanel"
                    aria-labelledby="tabs-2-tabJustify"
                  >
                    <div className="flex align-items-top justify-between">
                      <div className="TabContent">
                        <div className="TabContentHeader">Alex Acer</div>
                        <div className="TabContentDesc">Team Lead</div>
                        <div className="TabContentDetails">
                          Heya! I am Alex, an 18-year-old crypto enthusiast from
                          New Zealand. I am an avid crypto trader and have done
                          so for three years. My work is mainly trading, and I
                          help advise businesses on IRL. However, I am a creator
                          at heart. I love making things. For example, I have
                          completed my electric bike from scratch, table, shelf,
                          electric skateboard, just to name a few, and I love
                          electronics in general. So it wouldn't be a far cry to
                          say we will have future exclusive electronics gadgets
                          for LBH users. We hope everyone knows our team isn't
                          going anywhere! We are here to stay and will bring LBH
                          everywhere we go!
                        </div>
                      </div>
                      <img src={Team2} className="TabImage" />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tabs-3-Justify"
                    role="tabpanel"
                    aria-labelledby="tabs-3-tabJustify"
                  >
                    <div className="flex align-items-top justify-between">
                      <div className="TabContent">
                        <div className="TabContentHeader">Arimoro Daniel</div>
                        <div className="TabContentDesc">Web Developer</div>
                        <div className="TabContentDetails">
                          My name is Daniel, a web designer who hales from Osun,
                          Nigeria. I’ve been a freelance webdesigner since for 5
                          year. Due to my keen interest in blockchain, I’ve been
                          able to learn smart contract development as well as
                          minting dapp creation for Ethereum blockchain.
                          Everyone wants to be a part for something, perhaps
                          something even greater than themselves, for me, They
                          are my designs and codes This is what we LBH team
                          share with our entire community. LBH preaches
                          Connectivity, Socialization and Companionship It’s
                          never lonely in Lonely Box Head!!!!
                        </div>
                      </div>
                      <img src={Team3} className="TabImage" />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tabs-4-Justify"
                    role="tabpanel"
                    aria-labelledby="tabs-4-tabJustify"
                  >
                    <div className="flex align-items-top justify-between">
                      <div className="TabContent">
                        <div className="TabContentHeader">Adebimpe</div>
                        <div className="TabContentDesc">Ux Designer</div>
                        <div className="TabContentDetails">
                          I am Adeife Adeniran, a visual artist from Lagos,
                          Nigeria and creator of 'Box Head'. I'm a typical
                          introvert and I love everything creative (art, film,
                          music, etc). In my art practice, I experiment in
                          various media (photography, collage, digital) but
                          primarily I create works in painting. My work is
                          greatly informed by personal experiences and
                          observations, and I explore the theme of Loneliness
                          and associated emotions. I seek to use my art to evoke
                          conversations and raise awareness about Loneliness.
                        </div>
                      </div>
                      <img src={Team4} className="TabImage" />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tabs-5-Justify"
                    role="tabpanel"
                    aria-labelledby="tabs-5-tabJustify"
                  >
                    <div className="flex align-items-top justify-between">
                      <div className="TabContent">
                        <div className="TabContentHeader">Muhammad Musa</div>
                        <div className="TabContentDesc">Smart Contract Dev</div>
                        <div className="TabContentDetails">
                          I am Muhammad Musa, Full stack engineer by day and
                          DaPP developer by night, creator of 'Box Head Smart
                          Dapp'. Staying upto date with the latest trends and
                          technologies in IT is kinda like a passion and I love
                          to code and design (apart from when I get stuck in an
                          issue and try to debug it for 12 hours straight xD). I
                          also like playing games and mostly spend my free time
                          playing games or football.
                        </div>
                      </div>
                      <img src={Team5} className="TabImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex align-items-center justify-center flex-col md:flex-row">
          <div className="TeamSection">
            <img src={Team1} />
            <div className="flex align-items-center justify-between MT-16">
              <div>
                <div className="Name">Adeife Adeniran</div>
                <div className="Profession">Artist</div>
              </div>
              <div className="SocialIcons">
                <a
                  href="https://www.instagram.com/adeife.adeniran/"
                  target="_blank"
                >
                  <img src={SocialInsta} className="CursorPointer" />
                </a>
                <a href="https://twitter.com/boxheadadeife" target="_blank">
                  <img src={SocialTwitter} className="CursorPointer" />
                </a>
              </div>
            </div>
          </div>
          <div className="TeamSection">
            <img src={Team2} />
            <div className="flex align-items-center justify-between MT-16">
              <div>
                <div className="Name">Alex Acer</div>
                <div className="Profession">Team Lead</div>
              </div>
              <div className="SocialIcons">
                <a href="https://www.instagram.com/alexacer1/" target="_blank">
                  <img src={SocialInsta} className="CursorPointer" />
                </a>
                <a href="https://twitter.com/Alex37464268" target="_blank">
                  <img src={SocialTwitter} className="CursorPointer" />
                </a>
              </div>
            </div>
          </div>
          <div className="TeamSection">
            <img src={Team3} />
            <div className="flex align-items-center justify-between MT-16">
              <div>
                <div className="Name">Arimoro Daniel</div>
                <div className="Profession">Web Developer</div>
              </div>
              <div className="SocialIcons">
                <a href="https://www.instagram.com/hr.dannie/" target="_blank">
                  <img src={SocialInsta} className="CursorPointer" />
                </a>
                <a href="https://twitter.com/Hr_dannie" target="_blank">
                  <img src={SocialTwitter} className="CursorPointer" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex align-items-center justify-center MB-42 flex-col md:flex-row">
          <div className="TeamSection">
            <img src={Team4} />
            <div className="flex align-items-center justify-between MT-16">
              <div>
                <div className="Name">Adebimpe</div>
                <div className="Profession">Ux Designer</div>
              </div>
              <div className="SocialIcons">
                <a
                  href="https://www.instagram.com/adebimpeomolaso/"
                  target="_blank"
                >
                  <img src={SocialInsta} className="CursorPointer" />
                </a>
                <a href="https://twitter.com/AdebimpeOmolaso" target="_blank">
                  <img src={SocialTwitter} className="CursorPointer" />
                </a>
              </div>
            </div>
          </div>
          <div className="TeamSection">
            <img src={Team5} />
            <div className="flex align-items-center justify-between MT-16">
              <div>
                <div className="Name">Muhammad Musa</div>
                <div className="Profession">Smart Contract Dev</div>
              </div>
              <div className="SocialIcons">
                <a
                  href="https://www.instagram.com/musa_qureshi11/"
                  target="_blank"
                >
                  <img src={SocialInsta} className="CursorPointer" />
                </a>
                <a href="https://twitter.com/MusaQureshi95" target="_blank">
                  <img src={SocialTwitter} className="CursorPointer" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Section Seven*/}
      <div className="SectionSeven">
        <div className="container">
          <div className="FAQHeading">FREQUENTLY ASKED QUESTIONS</div>
          <div className="accordion AccordianStyle" id="accordionExample">
            <div className="accordion-item Tab MB-8">
              <h2
                className="accordion-header AccordianHeaderStyle mb-0 Tab"
                id="SectionSevenHeadingOne"
              >
                <button
                  className="H-44 accordion-button AccordianHeaderStyle relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#SectionSevenCollapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Where is LBH purchasable?
                </button>
              </h2>
              <div
                id="SectionSevenCollapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="SectionSevenHeadingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body AccordianBodyStyle py-4 px-5">
                  Opensea, please use official links from the discord or click
                  the popup on this website.
                </div>
              </div>
            </div>
            <div className="accordion-item Tab MB-8">
              <h2
                className="accordion-header AccordianHeaderStyle mb-0 Tab"
                id="SectionSevenHeadingTwo"
              >
                <button
                  className="H-44 accordion-button AccordianHeaderStyle relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#SectionSevenCollapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What date is the launch?
                </button>
              </h2>
              <div
                id="SectionSevenCollapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="SectionSevenHeadingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body AccordianBodyStyle py-4 px-5">
                  The launch date is TBA. So please don't purchase fake
                  collections of LBH until officially announced from all social
                  media platforms.
                </div>
              </div>
            </div>
            <div className="accordion-item Tab MB-8">
              <h2
                className="accordion-header AccordianHeaderStyle mb-0 Tab"
                id="SectionSevenHeadingThree"
              >
                <button
                  className="H-44 accordion-button AccordianHeaderStyle relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#SectionSevenCollapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What is the total quantity and are there any giveaways?
                </button>
              </h2>
              <div
                id="SectionSevenCollapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="SectionSevenHeadingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body AccordianBodyStyle py-4 px-5">
                  Only 2000 LBH will ever exist. As a result, there will be
                  significant surprises and giveaways for LBH holders.
                </div>
              </div>
            </div>
            <div className="accordion-item Tab MB-8">
              <h2
                className="accordion-header AccordianHeaderStyle mb-0 Tab"
                id="SectionSevenHeadingFour"
              >
                <button
                  className="H-44 accordion-button AccordianHeaderStyle relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#SectionSevenCollapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Will holders have their voices heard?
                </button>
              </h2>
              <div
                id="SectionSevenCollapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="SectionSevenHeadingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body AccordianBodyStyle py-4 px-5">
                  We will take feedback from holders as this is the best way to
                  move forward. We are not a dao, and recently many other NFT's
                  have used these buzz words, which has given the word some
                  negative connotation. We have seen what happens to projects
                  that claim to provide holders "control of the direction."
                  Instead, we have an incredibly sophisticated and creative team
                  that will attempt to bring as much value as we can to LBH! We
                  are for the people by the people!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Section Eight*/}
      <div className="SectionEight">
        <div className="container flex align-top justify-center MT-85 flex-col md:flex-row">
          <div className="flex align-top justify-center">
            <div className="LeftTextContainer">
              <div className="HittingHardQuestions">HITTING HARD QUETIONS</div>
              <div className="HittingHardDescription">
                Let's Skip the bullshit questions. We will answer what you want
                to know!!!
              </div>
            </div>
          </div>
          <div className="flex align-top justify-center">
            <div className="accordion AccordianStyle" id="accordionExample2">
              <div className="accordion-item Tab2 MB-8">
                <h2
                  className="accordion-header AccordianHeaderStyle2 mb-0 Tab2"
                  id="HittingHardHeadingOne"
                >
                  <button
                    className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#HittingHardCollapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Is the team anonymous?
                  </button>
                </h2>
                <div
                  id="HittingHardCollapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="HittingHardHeadingOne"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body AccordianBodyStyle py-4 px-5">
                    Our team is fully doxxed. We don't shy away from
                    representing LBH! We are here for the long term, and you
                    will see these efforts throughout our journey!
                  </div>
                </div>
              </div>
              <div className="accordion-item Tab2 MB-8">
                <h2
                  className="accordion-header AccordianHeaderStyle2 mb-0 Tab2"
                  id="HittingHardHeadingTwo"
                >
                  <button
                    className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#HittingHardCollapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Why is this different from a 3d derivative animal project
                    that comes out every week?
                  </button>
                </h2>
                <div
                  id="HittingHardCollapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="HittingHardHeadingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body AccordianBodyStyle py-4 px-5">
                    We are one of the first people, if not the first nft, to
                    represent an underrepresented group, those who are lonely or
                    have been in the past! The significance of first holds
                    excellent value. y!
                  </div>
                </div>
              </div>
              <div className="accordion-item Tab2 MB-8">
                <h2
                  className="accordion-header AccordianHeaderStyle2 mb-0 Tab2"
                  id="HittingHardHeadingThree"
                >
                  <button
                    className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#HittingHardCollapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    How long ago was LBH created?
                  </button>
                </h2>
                <div
                  id="HittingHardCollapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="HittingHardHeadingThree"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body AccordianBodyStyle py-4 px-5">
                    Yes, you heard that right! LBH was an idea of Adeife years
                    ago. LBH was first publically illustrated on Adeife's
                    Instagram on June 24 2020. However, Adeife first drew an LBH
                    in February 2020, so it's an artist journey throughout the
                    years.
                  </div>
                </div>
              </div>
              <div className="accordion-item Tab2 MB-8">
                <h2
                  className="accordion-header AccordianHeaderStyle2 mb-0 Tab2"
                  id="HittingHardHeadingFour"
                >
                  <button
                    className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#HittingHardCollapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Why purchase an LBH NFT?
                  </button>
                </h2>
                <div
                  id="HittingHardCollapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="HittingHardHeadingFour"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body AccordianBodyStyle py-4 px-5">
                    When purchasing an LBH, you are accessing a community with
                    similar interests. In addition, you're also shining light on
                    a topic that's not talked about enough, which is Mental
                    Health!!! You will be next to Adeife as he progresses
                    through his artist journey!
                  </div>
                </div>
              </div>
              <div className="accordion-item Tab2 MB-8">
                <h2
                  className="accordion-header AccordianHeaderStyle2 mb-0 Tab2"
                  id="HittingHardHeadingFive"
                >
                  <button
                    className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#HittingHardCollapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    Will we adapt to the fast pace changing space of the
                    metaverse?
                  </button>
                </h2>
                <div
                  id="HittingHardCollapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="HittingHardHeadingFive"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body AccordianBodyStyle py-4 px-5">
                    Of course! We will never leave LBH as just an ERC-721 token.
                    Collabing with cartoon shows is another thing we will work
                    hard to do. We would love to see our LBH characters in shows
                    everyone loves to watch! In addition, we aim for LBH to
                    integrate with different and popular ecosystems such as
                    SandBox etc.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex align-items-center justify-around flex-col md:flex-row">
            <div className="FooterLeftText MB-43">
              © 2021 Lonely Box Head |{" "}
              <span
                onClick={() => navigate("/privacy-policy")}
                className="CursorPointer"
              >
                Terms and conditions
              </span>
            </div>
            <div className="FlexDisplay basis-full pt-16 W-350 MB-43">
              <a
                href="https://www.youtube.com/channel/UCPUuQcasRV7K70C4CAggxsg"
                target="_blank"
              >
                <img
                  src={YoutubeIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a href="https://medium.com/@lonelyboxheads" target="_blank">
                <img src={MIcon} className="NavIconPadding CursorPointer" />
              </a>
              <a href="https://twitter.com/LonelyBoxHeads" target="_blank">
                <img
                  src={TwitterIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a href="https://google.com" target="_blank">
                <img
                  src={DiscordIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a
                href="https://www.instagram.com/lonelyboxheadsofficial/"
                target="_blank"
              >
                <img
                  src={InstagramIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="LastFooterText">
            ALL IMAGES MADE BY ADEFIE ADENIRAN
          </div>
        </div>
      </div>
    </div>
  );
}
