import React from "react";
import "./PrivacyPolicy.css";
import NavMidImage from "./assets/NavMidImage.png";
import InstagramIcon from "./assets/InstagramIcon.png";
import YoutubeIcon from "./assets/YoutubeIcon.png";
import MIcon from "./assets/MIcon.png";
import DiscordIcon from "./assets/DiscordIcon.png";
import TwitterIcon from "./assets/TwitterIcon.png";
import ShopButton from "./assets/ShopButton.png";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <div className="MainContainer">
      <div className="Section-one">
        <div className="LoopingMarquee">
          <div className="Track">
            <div className="Content">
              MINITING DATE TBA <span className="Circle">o</span> STILL HIDING
              IN THE METAVERSE <span className="Circle">o</span>MINITING DATE
              TBA <span className="Circle">o</span> STILL HIDING IN THE
              METAVERSE <span className="Circle">o</span>MINITING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>MINITING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>MINITING DATE TBA{" "}
              <span className="Circle">o</span> STILL HIDING IN THE METAVERSE{" "}
              <span className="Circle">o</span>
            </div>
          </div>
        </div>
        <div className="container">
          {/* NavBar */}
          <div className="NavBarMargins flex justify-between flex-col lg:flex-row">
            <div className="FlexDisplay basis-full pt-16 W-350">
              <img src={ShopButton} className="ShopButton CursorPointer" />
            </div>
            <div className="FlexDisplay basis-full pt-16 W-350">
              <div className="MidImage" onClick={() => navigate("/")}>
                <img src={NavMidImage} className="CursorPointer" />
              </div>
            </div>
            <div className="FlexDisplay basis-full pt-16 W-350">
              <a
                href="https://www.youtube.com/channel/UCPUuQcasRV7K70C4CAggxsg"
                target="_blank"
              >
                <img
                  src={YoutubeIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a href="https://medium.com/@lonelyboxheads" target="_blank">
                <img src={MIcon} className="NavIconPadding CursorPointer" />
              </a>
              <a href="https://twitter.com/LonelyBoxHeads" target="_blank">
                <img
                  src={TwitterIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a href="https://google.com" target="_blank">
                <img
                  src={DiscordIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
              <a
                href="https://www.instagram.com/lonelyboxheadsofficial/"
                target="_blank"
              >
                <img
                  src={InstagramIcon}
                  className="NavIconPadding CursorPointer"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container container-margin">
          <h1 className="main-heading">Terms and Conditions</h1>
          <p className="last-updated">Last Updated: Feb 6th, 2022</p>
        </div>
        <div className="container container-margin">
          <h1 className="intro-heading">Introduction</h1>
          <p className="intro-text">
            Lonely Box Heads (LBH) is a collection of 2000 unique Box Head NFTs
            deployed on the Ethereum blockchain.
            <br /> This website is an informational website that powers the
            exchange of digital collectables only.
            <br /> Hence users are responsible for their wallet safety and
            security.
            <br /> The LBH will NEVER ask for your private information,
            including passcodes, seed phrases, or related data.
            <br /> The LBH smart contract runs on the Ethereum blockchain, which
            makes it impossible to undo, reverse, or cancel any transaction
            made.
            <br /> By using this website and its provided services, you have
            agreed to the terms and conditions of the LBH team.
            <br /> This website and its connected services are provided “as is”
            and “as available” without a warranty of any kind. Using this
            website,
            <br /> you accept sole responsibility for all transactions involving
            Lonely Box Heads.
            <br /> LBH and its merchandise are bound by the terms of use of any
            third-party platform/marketplaces where LBH NFT can be bought.{" "}
          </p>
        </div>
        <div className="container container-margin">
          <h1 className="general-heading">GENERAL INFORMATION</h1>
        </div>
        <div
          className="accordion Accordian-Style mx-auto"
          id="accordionExample2"
        >
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header Accordian-Header-Style mb-0 Tab2"
              id="HittingHardHeadingOne"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1.1 Age Restrictions
              </button>
            </h2>
            <div
              id="HittingHardCollapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="HittingHardHeadingOne"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body Accordian-Body-Style py-4 px-5">
                NFTs provided by Issuer is intended for purchase and use by persons 18 years of age or older. You acknowledge and agree that you are not permitted to visit Issuer’s website or purchase the NFTs and shall not access Issuer’s website or buy any NFTs provided by Issuer if you are under 18. By using the Platform, you certify that you are at least 18 years of age and agree to provide us with accurate information and verification concerning your age or identity if we request it. You also agree not to assist anyone under 18 in accessing Issuer’s website or the NFTs or attempt to contact anyone under 18 while accessing or using the Platform.
              </div>
            </div>
          </div>
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header AccordianHeaderStyle2 mb-0 Tab2"
              id="HittingHardHeadingTwo"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                1.2 Limitations Period
              </button>
            </h2>
            <div
              id="HittingHardCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="HittingHardHeadingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body AccordianBodyStyle py-4 px-5">
                You agree that regardless of any statute or law to the contrary, any claim or cause of action you may have arising out of or related to the use of any NFTs or the Platform must be filed within one (1) year after such claim or cause of action arose or be forever barred.
              </div>
            </div>
          </div>
        </div>

        <div className="container container-margin">
          <h1 className="general-heading">OTHER INFORMATION</h1>
        </div>
        <div
          className="accordion Accordian-Style mx-auto"
          id="accordionExample2"
        >
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header Accordian-Header-Style mb-0 Tab2"
              id="HittingHardHeadingTwo"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseThree"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                PURCHASE AND OWNERSHIP
              </button>
            </h2>
            <div
              id="HittingHardCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="HittingHardHeadingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body Accordian-Body-Style py-4 px-5">
                Ownership of Lonely Box Heads NFT can only be acquired by minting via this website during public mint, presale, or purchase from our collection on third-party marketplaces like www.opensea.io.
                Once you purchase, you own the underlying Lonely Box Head NFT completely and can choose to resell, show off as pfp, include the art design in a merch.
                Ownership is meditated only by the smart contract on the Ethereum blockchain: at no point may we freeze, seize, or otherwise modify the ownership of the Lonely Box Head.
                “Own” means your rights with respect to an NFT you have purchased or otherwise rightfully acquired from a legitimate source, where proof of such purchase is recorded on the applicable blockchain. To any NFT that you Own, you agree that you Own that NFT by any description that accompanies that NFT. The Issuer may, at its option, use third party platforms or wallet extensions (which may be owned or operated by third parties) to sell NFTs (“Third Party Sites”). You agree to adhere to any applicable terms of service or privacy policies applicable to the use of any Third-Party Sites.
                AN NFT THAT YOU OWN WILL BE TRANSFERABLE, BUT ANY TRANSFEREE WILL BE SUBJECT TO THESE NFT TERMS.
              </div>
            </div>
          </div>
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header Accordian-Header-Style mb-0 Tab2"
              id="HittingHardHeadingTwo"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseFour"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Risks of NFTs
              </button>
            </h2>
            <div
              id="HittingHardCollapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="HittingHardHeadingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body Accordian-Body-Style py-4 px-5">
                Once you Own any NFT, you are responsible for any loss or damage to, or loss of access to, the NFT and neither Issuer nor any of its licensors shall have any liability in such circumstances, regardless of cause. You expressly understand and agree that your use of the Platform and any NFTs is at your sole risk and that the Platform and NFTs are provided “as is” and “as available.”
              </div>
            </div>
          </div>
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header Accordian-Header-Style mb-0 Tab2"
              id="HittingHardHeadingTwo"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseFive"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Terms of Sale
              </button>
            </h2>
            <div
              id="HittingHardCollapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="HittingHardHeadingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body Accordian-Body-Style py-4 px-5">
                All sales of NFTs are final. Once you have purchased an NFT, you should promptly take the necessary steps to complete your transaction (e.g., activate or download any content or secure any seed phrase or corresponding private key). We encourage the use of secure, offline storage measures for NFTs. You may purchase NFTs using real or virtual/cryptocurrency, where multiple payment options are made available to you. You may resell or otherwise transfer an NFT that you Own where these NFT Terms and applicable law permit this. Any sale or transfer must provide the transfer of all of your rights then outstanding for such NFT. Anyone receiving such NFTs from you agrees to and is bound by these NFT Terms. You are strictly forbidden to sell, swap, donate, give away, transfer, or otherwise dispose of your purchased NFT to anyone who is under (18) years or younger.
              </div>
            </div>
          </div>
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header Accordian-Header-Style mb-0 Tab2"
              id="HittingHardHeadingTwo"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseSix"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Modifications
              </button>
            </h2>
            <div
              id="HittingHardCollapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="HittingHardHeadingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body Accordian-Body-Style py-4 px-5">
                We reserve the right to modify the LONELY BOX HEADs roadmap (or any parts of any associated software or applications relating to that) with or without notice at any time, provided, however, that we will use reasonable efforts to provide advance notice of such action. Issuer (including, without limitation, our licensors) shall not be liable to you or any third party for any modification, suspension or discontinuance of the NFTs or the Platform or any associated software, applications or functionality.
              </div>
            </div>
          </div>
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header Accordian-Header-Style mb-0 Tab2"
              id="HittingHardHeadingTwo"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseSeven"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                DISCLAIMER OF WARRANTY
              </button>
            </h2>
            <div
              id="HittingHardCollapseSeven"
              className="accordion-collapse collapse "
              aria-labelledby="HittingHardHeadingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body Accordian-Body-Style py-4 px-5">
                THERE IS NO WARRANTY FOR THE PLATFORM OR ANY NFTS TO THE EXTENT PERMITTED BY APPLICABLE LAW. EXCEPT WHEN OTHERWISE STATED IN WRITING, ISSUER OR OTHER PARTIES PROVIDE THE PLATFORM AND NFTS “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
              </div>
            </div>
          </div>
          <div className="accordion-item Tab2 MB-8">
            <h2
              className="accordion-header Accordian-Header-Style mb-0 Tab2"
              id="HittingHardHeadingTwo"
            >
              <button
                className="H-44 accordion-button AccordianHeaderStyle2 relative flex items-center w-full py-4 px-5 text-base text-left border-0 transition focus:outline-none Tab2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#HittingHardCollapseEight"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                ASSUMPTION OF RISK
              </button>
            </h2>
            <div
              id="HittingHardCollapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="HittingHardHeadingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body Accordian-Body-Style py-4 px-5">
                You acknowledge that NFTs carry the following risks, among others. We expressly disclaim any responsibility for these risks.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
